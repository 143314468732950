import React, { Component } from "react";
import LogoText from "../assets/G_Logo_Text_White.js";

export default class CookiePolicy extends Component {
  render() {
    return (
      <div className="h-full w-full flex flex-col bg-white relative">
        <div className="fixed p-4 w-full flex items-center bg-blue-darkest border-b-4 border-blue-darker">
          <div
            className="cursor-pointer rounded-full w-8 h-8 material-icons text-white bg-blue-darker flex items-center justify-center mr-8"
            onClick={() => this.props.togglePolicy()}
          >
            keyboard_backspace
          </div>
          <div className="w-48">
            <LogoText />
          </div>
          <div className="flex-1 flex justify-end text-brown">
            <i class="fas fa-cookie-bite text-2xl mr-8" />
          </div>
        </div>

        <div className="p-6 mt-16 font-sans text-blue-darkest flex-1 w-2/3">
          <div className="pb-4">
            <h1 className="font-title-sans font-bold">
              Cookie Policy for Granditude
            </h1>
            <p className="pt-2">
              This is the Cookie Policy for Granditude, accessible from
              https://app.granditude.com
            </p>
          </div>

          <div className="py-4">
            <h3 className="font-title-sans font-bold">What Are Cookies</h3>
            <p className="py-4">
              As is common practice with almost all professional websites this
              site uses cookies, which are tiny files that are downloaded to
              your computer, to improve your experience. This page describes
              what information they gather, how we use it and why we sometimes
              need to store these cookies. We will also share how you can
              prevent these cookies from being stored however this may downgrade
              or 'break' certain elements of the sites functionality.
            </p>
            <p className="pt-2">
              For more general information on cookies see the Wikipedia article
              on HTTP Cookies.
            </p>
          </div>

          <div className="py-4">
            <h3 className="font-title-sans font-bold">How We Use Cookies</h3>
            <p className="py-4">
              We use cookies for a variety of reasons detailed below.
              Unfortunately in most cases there are no industry standard options
              for disabling cookies without completely disabling the
              functionality and features they add to this site. It is
              recommended that you leave on all cookies if you are not sure
              whether you need them or not in case they are used to provide a
              service that you use.
            </p>
          </div>

          <div className="py-4">
            <h3 className="font-title-sans font-bold">Disabling Cookies</h3>
            <p className="py-4">
              You can prevent the setting of cookies by adjusting the settings
              on your browser (see your browser Help for how to do this). Be
              aware that disabling cookies will affect the functionality of this
              and many other websites that you visit. Disabling cookies will
              usually result in also disabling certain functionality and
              features of the this site. Therefore it is recommended that you do
              not disable cookies.
            </p>
          </div>

          <div className="py-4">
            <h3 className="font-title-sans font-bold">The Cookies We Set</h3>
            <ul className="py-4">
              <li className="pb-2">
                <h4>Account Related Cookies</h4>
                If you create an account with us then we will use cookies for
                the management of the signup process and general administration.
                These cookies will usually be deleted when you log out however
                in some cases they may remain afterwards to remember your site
                preferences when logged out.
              </li>
              <li>
                <h4>Login Related Cookies</h4>
                We use cookies when you are logged in so that we can remember
                this fact. This prevents you from having to log in every single
                time you visit a new page. These cookies are typically removed
                or cleared when you log out to ensure that you can only access
                restricted features and areas when logged in.
              </li>
            </ul>
          </div>

          <div className="py-4">
            <h3 className="font-title-sans font-bold">Third Party Cookies</h3>
            <p className="py-4">
              In some special cases we also use cookies provided by trusted
              third parties. The following section details which third party
              cookies you might encounter through this site.
            </p>
            <ul className="pt-2">
              <li className="pb-2">
                From time to time we test new features and make subtle changes
                to the way that the site is delivered. When we are still testing
                new features these cookies may be used to ensure that you
                receive a consistent experience whilst on the site whilst
                ensuring we understand which optimisations our users appreciate
                the most.
              </li>
            </ul>
          </div>

          <div className="py-4">
            <h3 className="font-title-sans font-bold">More Information</h3>
            <p className="py-4">
              Hopefully that has clarified things for you and as was previously
              mentioned if there is something that you aren't sure whether you
              need or not it's usually safer to leave cookies enabled in case it
              does interact with one of the features you use on our site.
            </p>
            <p className="py-2">
              However if you are still looking for more information then you can
              contact us through one of our preferred contact method:
            </p>
            <ul className="pt-2">
              <li className="pb-2">Email: info@granditude.com</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
