const TOGGLE_SCROLLING = "TOGGLE_SCROLLING";

export function toggleScrolling(modal) {
  return {
    type: TOGGLE_SCROLLING,
  };
}

const initialState = {
  isScrollingView: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_SCROLLING:
      return Object.assign({}, state, {
        isScrollingView: !state.isScrollingView
      });
    default:
      return state;
  }
}
