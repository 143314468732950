import axios from "axios";

const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
const SET_TOKEN = "SET_TOKEN";
const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
const USER_LOGIN_AVAILABLE_APPS = "USER_LOGIN_AVAILABLE_APPS";
const USER_LOGIN_REMOVE_ERROR = "USER_LOGIN_REMOVE_ERROR";
const USER_LOGIN_REMOVE_EMAIL_ERROR = "USER_LOGIN_REMOVE_EMAIL_ERROR";
const USER_LOGIN_REMOVE_PASSWORD_ERROR = "USER_LOGIN_REMOVE_PASSWORD_ERROR";
const AGREE_TO_COOKIES = "AGREE_TO_COOKIES";
const USER_CLEAR_ALL = "USER_CLEAR_ALL";

function parseJwt(token) {
  return JSON.parse(atob(token.split(".")[1]));
}

export function login(email, password) {
  return (dispatch) => {
    if (email.length > 0 && password.length > 0) {
      axios
        .post(
          process.env.REACT_APP_API_HOST + "/auth/login",
          { email, password },
          { withCredentials: true }
        )
        .then((res) => {
          const token = parseJwt(res.headers["x-access-token"]);
          let availableApps = [...token.apps];
          availableApps.splice(
            availableApps.indexOf(
              availableApps.find(
                (app) => app.name.toLowerCase === "suite" || app.id === 0
              )
            ),
            1
          );
          if (availableApps.length > 1) {
            dispatch(setAvailableApps(token));
          } else {
            window.location.href = process.env.REACT_APP_LOGIN_REDIRECT
              ? process.env.REACT_APP_LOGIN_REDIRECT
              : "http://" + availableApps[0].url;
            localStorage.setItem(
              "redirect",
              process.env.REACT_APP_LOGIN_REDIRECT
                ? process.env.REACT_APP_LOGIN_REDIRECT
                : "http://" + availableApps[0].url
            );
          }
          return res;
        })
        .catch((error) => {
          dispatch(failure(true));
        });
    }
  };
}
export const redirectToApp = (appUrl) => {
  window.location.href = process.env.REACT_APP_LOGIN_REDIRECT
    ? process.env.REACT_APP_LOGIN_REDIRECT
    : "http://" + appUrl;
  localStorage.setItem(
    "redirect",
    process.env.REACT_APP_LOGIN_REDIRECT
      ? process.env.REACT_APP_LOGIN_REDIRECT
      : "http://" + appUrl
  );
};

export const clearAll = () => {
  return {
    type: USER_CLEAR_ALL,
  };
};

function failure(error) {
  return {
    type: USER_LOGIN_FAILURE,
    error: error,
  };
}

export function removeError() {
  return {
    type: USER_LOGIN_REMOVE_ERROR,
  };
}

export function removeEmailError() {
  return {
    type: USER_LOGIN_REMOVE_EMAIL_ERROR,
  };
}

export function removePasswordError() {
  return {
    type: USER_LOGIN_REMOVE_PASSWORD_ERROR,
  };
}

export function setAvailableApps(token) {
  return {
    type: USER_LOGIN_AVAILABLE_APPS,
    availableApps: token.apps,
    token,
  };
}

export function agreeToCookies() {
  localStorage.setItem("AgreeToCookies", "true");
  return {
    type: AGREE_TO_COOKIES,
  };
}

const initialState = {
  token: {},
  availableApps: [],
  loggedIn: true,
  loggingIn: false,
  errorResponse: [],
  emailError: false,
  passwordError: false,
  error: false,
  agreeToCookies: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return Object.assign({}, state, {
        loggingIn: true,
      });
    case SET_TOKEN:
      return Object.assign({}, state, {
        loggedIn: true,
        loggingIn: false,
        token: action.token,
      });
    case USER_LOGIN_FAILURE:
      return Object.assign({}, state, {
        loggingIn: false,
        error: action.error,
        emailError: true,
        passwordError: true,
      });
    case USER_LOGIN_AVAILABLE_APPS:
      return Object.assign({}, state, {
        availableApps: action.availableApps,
        token: action.token,
      });
    case USER_LOGIN_REMOVE_EMAIL_ERROR:
      return Object.assign({}, state, {
        emailError: false,
      });
    case USER_LOGIN_REMOVE_PASSWORD_ERROR:
      return Object.assign({}, state, {
        passwordError: false,
      });
    case USER_LOGIN_REMOVE_ERROR:
      return Object.assign({}, state, {
        error: false,
      });
    case AGREE_TO_COOKIES:
      return Object.assign({}, state, {
        agreeToCookies: true,
      });
    case USER_CLEAR_ALL:
      return Object.assign({}, state, {
        token: {},
        availableApps: [],
        loggedIn: true,
        loggingIn: false,
        errorResponse: [],
        emailError: false,
        passwordError: false,
        error: false,
      });
    default:
      return state;
  }
}
