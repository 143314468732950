import React from "react";
export default () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 162 163"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.8876 114.058C85.3099 127.07 76.1326 133.66 62.0134 133.66C47.3434 133.66 40.8974 127.436 36.6742 119.657L8.22314 136.105C18.4477 154.331 37.7856 163 60.9021 163C89.3363 163 117.359 148.4 119.457 114.058H86.8876Z"
      fill="#002D47"
      fill-opacity="0.5"
    />
    <g opacity="0.96">
      <circle cx="145.866" cy="101.902" r="15.6286" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.1314 14.7642V3.42824H119.583V109.008C119.583 110.917 119.507 112.769 119.359 114.565H86.6897C86.9818 112.835 87.1314 110.983 87.1314 109.008V98.7837C79.7963 107.897 69.1272 113.232 54.6794 113.232C23.1165 113.232 0 87.4478 0 56.774C0 26.1002 23.1165 0.316406 54.6794 0.316406C69.1272 0.316406 79.7963 5.65098 87.1314 14.7642ZM33.3411 56.774C33.3411 72.1109 44.4548 82.5578 60.2362 82.5578C76.0177 82.5578 87.1314 72.1109 87.1314 56.774C87.1314 41.4371 76.0177 30.9902 60.2362 30.9902C44.4548 30.9902 33.3411 41.4371 33.3411 56.774Z"
        fill="#002D47"
      />
    </g>
  </svg>
);
