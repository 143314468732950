import React from "react";

export const Button = (props) => {
  return (
    <div
      form={props.form}
      type={props.type}
      onClick={props.onClick}
      className={`${
        props.disabled
          ? "bg-grey-light text-grey cursor-not-allowed"
          : "bg-blue hover:bg-blue-dark text-white cursor-pointer shadow-md"
      }  rounded-full font-medium  font-title-sans  h-12 whitespace-no-wrap flex items-center  justify-center w-full px-3 text-lg tracking-wide`}
    >
      {props.label}
    </div>
  );
};
export default Button;
