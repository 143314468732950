import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { history } from "../helpers/history";
import { LargeLoadingSpinner } from "../components/LargeLoadingSpinner/LargeLoadingSpinner.js";

import LogoIcon from "../assets/G_Logo_Text_Icon.js";

export class InviteConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "Please wait while we activate your account.",
      error: false,
      success: false,
      showButton: false,
    };
  }

  componentDidMount = async () => {
    let searchQuery = window.location.search;
    let tokenQuery = searchQuery
      .split("token=")
      .pop()
      .split("&")[0];
    await axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/auth/invite/confirm-token?token=" +
          tokenQuery
      )
      .then((res) => {
        if (res.status !== 204) {
          this.setState({
            success: true,
            message: "Your account is now active.",
            showButton: true,
          });
        } else {
          this.setState({
            success: true,
            message: (
              <div className="w-full text-center">
                Unfortunately something has gone wrong.
              </div>
            ),
            error: true,
            showButton: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          message: (
            <div className="w-full text-center">
              Unfortunately something has gone wrong.
              <div className="text-lg">
                If the link we gave you is not working
              </div>
              <div className="text-lg">
                please contact us at support@granditude.com
              </div>
            </div>
          ),
          error: true,
          showButton: true,
        });
      });
  };

  buttonClick = () => {
    if (localStorage.getItem("redirect")) {
      window.location = localStorage.getItem("redirect");
    } else {
      history.push("/");
    }
  };
  render() {
    return (
      <div className="h-screen text-web-blue">
        <div className="absolute top-0 left-0 w-6 h-6 xxxl:w-8 xxxl:h-8 xxl:w-8 xxl:h-8 m-6">
          <LogoIcon />
        </div>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="text-3xl w-full text-center text-teal-darker h-10">
            {this.state.success && !this.state.error && "Success!"}
          </div>
          <div className="py-1 text-2xl">{this.state.message}</div>
          <div className="h-32 flex items-center flex-col">
            {!this.state.success && !this.state.error && (
              <div className="mt-6">{LargeLoadingSpinner}</div>
            )}

            <div className="pb-8">
              {this.state.success &&
                !this.state.error &&
                "Please check your email for your login in details."}
            </div>
            {this.state.showButton && (
              <div
                onClick={() => this.buttonClick()}
                className={`${
                  this.state.error
                    ? "bg-red hover:bg-red-dark text-white cursor-pointer shadow-md"
                    : "bg-blue hover:bg-blue-dark text-white cursor-pointer shadow-md"
                }  rounded-full font-medium w-48 font-title-sans  flex items-center justify-center h-12  text-lg tracking-wide`}
              >
                Ok
              </div>
            )}
          </div>
          <div className="absolute bottom-0 left-0 w-full text-blue-darkest font-title-sans pb-1 text-sm  w-full h-4 flex justify-center items-center mb-4">
            <span className="opacity-50">
              © 2020 Granditude AB. All Rights Reserved
            </span>
            <i class="fas fa-code text-blue ml-2" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteConfirmation);
