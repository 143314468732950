import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';

import general from './general';
import login from './login';

const reducers = combineReducers({
  general,
  login
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
