import React from "react";
import PropTypes from "prop-types";

export const Input = (props) => {
  let errorStyling = "border-red text-red placeholder-red";
  let inputStyling =
    "focus:border-blue border-grey text-web-blue focus:text-blue placeholder-grey placeholder-opacity-75 ";
  let disabledStyling =
    "border-grey bg-grey-light placeholder-grey cursor-not-allowed";

  return (
    <div className="flex flex-col w-full">
      {props.label && (
        <label
          className={`${
            props.error ? "text-red" : "text-grey-dark"
          } flex flex-row text-xs font-light pb-1 pl-2 truncate w-full`}
        >
          {props.error ? (
            <div className="h-full flex items-center">
              {props.label}
              <i className="material-icons-outlined text-md pl-2 ">
                error_outline
              </i>{" "}
            </div>
          ) : (
            props.label
          )}
        </label>
      )}
      <input
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        className={` ${
          props.error
            ? errorStyling
            : props.disabled
            ? disabledStyling
            : inputStyling
        } border-1 border-solid rounded px-2 h-12 text-lg font-light outline-none`}
        type={props.type}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        icon={props.icon}
        label={props.label}
        onKeyDown={props.onChange}
      />
    </div>
  );
};

Input.propTypes = {
  /** Optional: If the input field is required */
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Optional: if the input has an error */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** The type of input field */
  type: PropTypes.string,
  /** Unique name of input field */
  name: PropTypes.string,
  /** Optional: On change function */
  onChange: PropTypes.func,
  /** Optional: Value of the input field */
  value: PropTypes.string,
  /** Placeholder of the input field */
  placeholder: PropTypes.string,
  /** Optional: Icon of the input field */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Optional: Label for the input field */
  label: PropTypes.string,
  /** Optional: Specific to custom email error checking fucntions */
  contenterror: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Input.defaultProps = {
  required: null,
  error: null,
  icon: null,
  contenterror: null,
  label: null,
  placeholder: null,
  onClick: () => {},
  onChange: () => {},
};
