import React from "react";
import { Trans } from "@lingui/macro";

export const LoginLoading = () => {
  return (
    <div className="text-center mt-16 pt-1">
      <div className="mt-16 pt-6 font-sans text-grey-darker font-light font-sans text-sm">
        <Trans>Just a moment while we retrieve your data...</Trans>
      </div>
    </div>
  );
};
