import React, { Component } from "react";
import { connect } from "react-redux";

import { Router, Route } from "react-router-dom";

import { I18nProvider } from "@lingui/react";

import { history } from "./helpers/history";
import Config from "./Config";

import InviteConfirmation from "./pages/InviteConfirmation";

import Login from "./pages/Login";
import EN from "./locales/en/messages.js";
import SV from "./locales/sv/messages.js";
import FI from "./locales/fi/messages.js";

class App extends Component {
  componentDidMount() {
    if (window.location.search.includes("logout")) {
      localStorage.removeItem("redirect");
      let url = window.location.href.split("?");
      url.pop();
      window.location = url[0];
    } else {
      if (
        !window.location.href.includes("invite/confirm-token") &&
        localStorage.getItem("redirect")
      ) {
        window.location = localStorage.getItem("redirect");
      }
    }
  }
  render() {
    const catalogs = { fi: FI, en: EN, sv: SV };
    const language = navigator.language.substring(0, 2);
    return (
      <I18nProvider language={language} catalogs={catalogs}>
        <Router basename={Config.host} history={history}>
          <div className="h-screen w-screen ">
            <Route exact={true} path="/" component={Login} />
            <Route
              exact={true}
              path="/invite/confirm-token"
              component={InviteConfirmation}
            />
          </div>
        </Router>
      </I18nProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    displayCookieConfirmation: state.login.displayCookieConfirmation,
  };
};

export default connect(
  mapStateToProps,
  null
)(App);
