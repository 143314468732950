import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  login,
  removeError,
  removeEmailError,
  removePasswordError,
  agreeToCookies,
  redirectToApp,
  clearAll,
} from "../ducks/login";

import DefaultView from "../containers/LoginViews/DefaultView";

import CookiePolicy from "./CookiePolicy";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      loginView: "",
      email: "",
      password: "",
      showPolicy: false,
    };
  }

  componentDidMount() {
    let searchQuery = window.location.search;
    let loginQuery = searchQuery
      .split("login=")
      .pop()
      .split("&")[0];
    this.setState({ loginView: loginQuery });
  }

  handleSubmit = () => {
    console.log("hi");
    this.props.removeError();
    this.props.login(this.state.email, this.state.password);
  };

  handleInputChange = (event) => {
    if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
      this.handleSubmit();
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  viewPolicy = () => {
    this.setState((prevState) => ({ showPolicy: !prevState.showPolicy }));
  };

  clearAll = () => {
    this.setState({ email: "", password: "" }, () => this.props.clearAll());
  };

  render() {
    let availableApps = [...this.props.loginState.availableApps];
    availableApps.splice(
      availableApps.indexOf(
        availableApps.find(
          (app) => app.name.toLowerCase === "suite" || app.id === 0
        )
      ),
      1
    );

    let viewConfig = {
      handleInputChange: (event) => this.handleInputChange(event),
      handleSubmit: this.handleSubmit,
      removeEmailError: this.props.removeEmailError,
      removePasswordError: this.props.removePasswordError,
      email: this.state.email,
      emailError: this.props.loginState.emailError,
      password: this.state.password,
      passwordError: this.props.loginState.passwordError,
      loggingIn: this.props.loggingIn,
      error: this.props.loginState.error,
      agreeToCookies: this.props.loginState.agreeToCookies,
      availableApps: availableApps,
      redirectToApp: this.props.redirectToApp,
      clearAll: this.clearAll,
    };

    const loginView = (() => {
      switch (this.state.loginView) {
        default:
          return <DefaultView viewConfig={viewConfig} />;
      }
    })();

    return (
      <div className="w-full h-full relative">
        {this.state.showPolicy && (
          <div className="absolute w-full h-full z-50">
            <CookiePolicy togglePolicy={this.viewPolicy} />
          </div>
        )}
        {!localStorage.getItem("AgreeToCookies") && (
          <div className="absolute bottom-0 left-0 py-6 z-30 flex items-center w-full">
            <div className="bg-blue-darkest shadow-md flex flex items-center rounded-lg xxxl:mx-16 xxxl:px-10 mx-6 px-6 flex-1  py-4  ">
              <div className="font-title-sans text-white text-lg  font-light text-left flex-1 w-2/3">
                <span className="opacity-75">
                  Our Apps use cookies. <br /> Learn more about our
                </span>

                <span
                  className="inline-block mx-2 hover:text-green text-white  font-medium cursor-pointer"
                  onClick={() => this.viewPolicy()}
                >
                  <span className="pr-2 ">cookie policy</span>
                  <i class="fas fa-cookie-bite text-lg text-brown" />
                </span>
              </div>
              <div className="flex-1 flex flex-row justify-end">
                <div
                  onClick={() => this.props.agreeToCookies()}
                  className="bg-green hover:bg-green-dark text-white cursor-pointer shadow-md rounded-full font-title-sans xxl:h-12 h-12 whitespace-no-wrap flex items-center font-medium justify-center xxl:w-64 w-64 px-10 xxl:text-lg text-lg tracking-wide"
                >
                  Accept Cookies
                </div>
              </div>
            </div>
            <div className="xxl:w-2/5 lg:w-2/5" />
          </div>
        )}
        <div className="w-full h-full">{loginView}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginState: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    removeError: () => dispatch(removeError()),
    removeEmailError: () => dispatch(removeEmailError()),
    removePasswordError: () => dispatch(removePasswordError()),
    agreeToCookies: () => dispatch(agreeToCookies()),
    redirectToApp: (appUrl) => dispatch(redirectToApp(appUrl)),
    clearAll: () => dispatch(clearAll()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

Login.contextTypes = {
  store: PropTypes.object,
};
