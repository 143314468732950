import React from "react";

import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

import { Input } from "../../components/Input";
import { Button } from "../../components/Button";

import { LoginLoading } from "../LoginLoading";

import LogoText from "../../assets/G_Logo_Text_White.js";
import LogoIcon from "../../assets/G_Logo_Text_Icon.js";
import Background from "../../assets/Images/bg1.jpg";

const DefaultView = (props) => {
  const { i18n } = props;

  const setIcon = (id) => {
    let config = { icon: "" };
    switch (id) {
      case 2:
        config.icon = "rule";
        break;
      case 3:
        config.icon = "multiline_chart";
        break;
      case 4:
        config.icon = "perm_identity";
        break;
      case 5:
        config.icon = "addchart";
        break;
      case 8:
        config.icon = "publish";
        break;
      default:
        config.icon = "insert_chart_outlined";
    }
    return config;
  };
  return (
    <div className="flex w-full h-full text-blue-darkest">
      <div className="flex-1 px-26 py-10 h-full bg-white relative">
        <div className="absolute top-0 left-0 w-6 h-6 xxxl:w-8 xxxl:h-8 xxl:w-8 xxl:h-8 m-6">
          <LogoIcon />
        </div>
        <div className="flex flex-col  items-center justify-center h-full lg:-mt-18 xxxl:-mt-12 ">
          <div className="xxxl:mt-0 xxl:mt-0 md:pt-0 sm:pt-0 w-7/10  xxxl:w-2/5 xxl:w-2/3 text-2.5xl text-left">
            {props.viewConfig.availableApps.length > 1
              ? "Welcome back,"
              : "Hello,"}
          </div>
          <div className="w-7/10 xxxl:w-2/5 xxl:w-2/3 text-blue-darkest opacity-85 font-light text-xl text-left">
            {props.viewConfig.availableApps.length > 1 ? (
              <>
                <span className="">Apps available in your subscription:</span>
              </>
            ) : (
              <>
                <span className="">Welcome to a</span>{" "}
                <span className="font-normal">Grand World!</span>
              </>
            )}
          </div>
          {props.viewConfig.loggingIn ? (
            <LoginLoading />
          ) : props.viewConfig.availableApps.length > 1 ? (
            <div className="w-7/10 xxxl:w-2/5 xxl:w-2/3 flex flex-col pt-10 h-1/4">
              {props.viewConfig.availableApps.map((app) => {
                return (
                  <div
                    onClick={() => props.viewConfig.redirectToApp(app.url)}
                    className={`rounded shadow py-3 mb-2 flex flex justify-center pl-3 border-1 border-blue text-blue hover:bg-blue hover:text-white cursor-pointer `}
                  >
                    <div className="flex items-center flex-1 h-full">
                      <i className="material-icons text-xl xxxl:text-2xl xxl:text-2xl  pr-4">
                        {setIcon(app.id).icon}
                      </i>
                      <span className="text-md xxxl:text-lg xxl:text-lg ">
                        {app.name}
                      </span>
                    </div>
                    <div className="h-full flex items-center px-4">
                      <i className="material-icons">keyboard_tab</i>
                    </div>
                  </div>
                );
              })}
              <div className="w-full pt-8 font-light text-web-blue ">
                <div
                  onClick={() => props.viewConfig.clearAll()}
                  className=" pb-1 cursor-pointer flex items-center w-36 hover:text-blue"
                >
                  <i className="material-icons text-xl mr-2">
                    keyboard_backspace
                  </i>
                  Return to login.
                </div>
              </div>
            </div>
          ) : (
            <div className="w-7/10 xxxl:w-2/5 xxl:w-2/3 pt-10  h-1/4">
              <div className="mb-6 w-full">
                <Input
                  autoFocus={true}
                  disabled={
                    localStorage.getItem("AgreeToCookies") ? false : true
                  }
                  icon="email"
                  required={false}
                  onChange={(event) => {
                    props.viewConfig.handleInputChange(event);
                    props.viewConfig.removeEmailError();
                  }}
                  value={props.viewConfig.email}
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  error={props.viewConfig.emailError ? "true" : null}
                />
              </div>
              <div className="mb-2 w-full">
                <Input
                  disabled={
                    localStorage.getItem("AgreeToCookies") ? false : true
                  }
                  icon="lock"
                  required={false}
                  onChange={(event) => {
                    props.viewConfig.handleInputChange(event);
                    props.viewConfig.removePasswordError();
                  }}
                  value={props.viewConfig.password}
                  type={"password"}
                  name="password"
                  label="Password"
                  placeholder="Password"
                  error={props.viewConfig.passwordError ? "true" : null}
                />
              </div>
              <div className="h-1 py-1 w-full my-2 ">
                {props.viewConfig.error ? (
                  <div className="text-xs text-red font-title-sans font-light">
                    <Trans>Invalid credentials. Please try again.</Trans>
                  </div>
                ) : null}
              </div>
              <div className="w-full flex items-center justify-center mt-8 mb-6">
                <Button
                  disabled={
                    localStorage.getItem("AgreeToCookies") ? false : true
                  }
                  label={i18n._(t`Login`)}
                  onClick={() => props.viewConfig.handleSubmit()}
                />
              </div>
            </div>
          )}
        </div>
        <div className="absolute bottom-0 left-0 w-full text-blue-darkest font-title-sans pb-1 text-sm  w-full h-4 flex justify-center items-center mb-4">
          <span className="opacity-50">
            © 2021 Granditude AB. All Rights Reserved.
          </span>
          <i class="fas fa-code text-blue ml-2" />
        </div>
      </div>

      <div className=" xxl:w-2/5 lg:w-2/5 hidden  lg:flex lg:flex-col px-16 items-center justify-center bg-blue-darkest relative">
        <div
          className="z-10 w-full h-full absolute bg-cover bg-bottom"
          style={{ backgroundImage: "url(" + Background + ")" }}
        />
        <div className="z-12 w-full h-full absolute bg-blue-darkest opacity-75" />

        <div className="z-20 w-9/10 xxxl:w-7/10 xxl:w-9/10">
          <LogoText />
        </div>

        <div className="z-20 w-9/10 xxxl:w-7/10 xxl:w-9/10 text-white font-thin xxxl:font-light xxl:font-light pt-8 text-lg xxxl:text-xl xxl:text-xl">
          <div className="w-5/6 ">
            With{" "}
            <span className="font-normal xxxl:font-medium xxl:font-medium">
              smart
            </span>{" "}
            solutions, we live to help solve your technical problems so that you
            can focus on your{" "}
            <span className="font-normal xxxl:font-medium xxl:font-medium">
              business.
            </span>
          </div>
        </div>
        <div className="absolute w-full bottom-0 flex justify-center py-6 z-22">
          <a
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/granditude"
            target="_blank"
          >
            <div className="w-10 h-10 bg-white shadow-md cursor-pointer hover:bg-blue text-blue-darkest hover:text-white rounded-full mx-2 flex items-center justify-center">
              <i class="fab fa-linkedin-in  text-xl" />
            </div>
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.instagram.com/granditude/"
            target="_blank"
          >
            <div className="w-10 h-10 bg-white shadow-md cursor-pointer hover:bg-blue text-blue-darkest hover:text-white rounded-full mx-2 flex items-center justify-center">
              <i class="fab fa-instagram  text-xl" />
            </div>
          </a>
          <a
            rel="noopener noreferrer"
            href="https://twitter.com/granditude_com"
            target="_blank"
          >
            <div className="w-10 h-10 bg-white shadow-md cursor-pointer hover:bg-blue text-blue-darkest hover:text-white rounded-full mx-2 flex items-center justify-center">
              <i class="fab fa-twitter  text-xl" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
export default withI18n()(DefaultView);
